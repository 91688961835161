<template>

  <drawer-form
    ref="drawerForm"
    :title="model.id > 0?'编辑':'添加'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='图片' prop="logo">
      <image-upload v-model="model.logo"></image-upload>
    </a-form-model-item>

    <a-form-model-item label='话题' prop="name">
      <a-input v-model="model.name"/>
    </a-form-model-item>

    <a-form-model-item label='简介' prop="des">
      <a-input v-model="model.des"/>
    </a-form-model-item>

    <a-form-model-item label='排序' prop="sort">
      <a-input-number v-model="model.sort"/>
    </a-form-model-item>

  </drawer-form>

</template>

<script>

  export default {
    data () {
      return {
        isShow: false,
        model: {
          id: null,
          logo: null,
          name: null,
          des: null,
          sort:0,
        },
        rules:{
          sort:[]
        },
        loading: false,

      }
    },
    methods: {
      show (model = {}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.$refs.drawerForm.show()
      },
      hide () {
        this.$refs.drawerForm.hide()
      },

      handleSubmit () {
        if (!this.model.logo) {
          this.$message.error('上传图片不能为空')
          // return
        }
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },

      add () {
        this.loading = true
        this.$post('web/app-forum-topic', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      },
      edit () {
        this.loading = true
        this.$put('web/app-forum-topic', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      }

    },

  }
</script>

<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .news-image {
    width: 200px;
  }
</style>
